<template>
  <div>
    <h4>{{ i18n['profile-spouse-info'].tcSpouse }}</h4>
    <div class="specs">
      <h5>
        <a href="#" @click.prevent="individualStoreKeyAndRedirect(profile.spouse_ind_key)">{{
          profile.spouse_formal_name
        }}</a>
      </h5>
      <p v-if="!!profile.spouse_formal_name && !!profile.spouse_member_type_key">
        <strong>{{ i18n['profile-spouse-info'].tcMemberID }}:</strong>
        {{ profile.spouse_member_number }}
      </p>

      <button
        v-if="!profile.spouse_formal_name && !profile.spouse_member_number && !profile.widow_flag && isAbleToAddSpouse"
        @click="spouseClick(profile.ind_key)"
        class="btn btn-primary bottom-m-20"
      >
        {{ i18n['profile-spouse-info'].tcAddSpouse }}
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'profile-spouse-info',
  mixins: [translationMixin],
  props: {
    isAbleToAddSpouse: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
      default: () => {
        return {
          spouse_formal_name: '',
          spouse_member_number: '',
          spouse_ind_key: '',
        }
      },
    },
  },
  data() {
    return {
      i18n: { 'profile-spouse-info': {} },
    }
  },
  methods: {
    ...mapActions({
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setRouterBackLink: 'user/setRouterBackLink',
    }),
    async individualStoreKeyAndRedirect(data) {
      this.setSelectedIndividualKey(data)
      this.$emit('changeIndividual')
    },
    async spouseClick(data) {
      this.setRouterBackLink(this.$route.name)
      this.$router.push({ name: 'add-spouse' })
    },
  },
  async created() {
    this.i18n = await this.getComponentTranslations('profile-spouse-info')
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.bottom-m-20 {
  margin-bottom: 20px;
}
</style>
